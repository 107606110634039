// @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700");
// @import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@700;900");

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');


// $fontDefault: "Open Sans", Roboto, Helvetica, sans-serif;
// $fontSecondary: "Source Sans Pro", "Roboto Condensed", Helvetica, sans-serif;
$fontDefault: "Nunito", sans-serif;
$fontSecondary: "Nunito", sans-serif;

$aos-distance: 200px; // It has to be above import
@import 'node_modules/aos/src/sass/aos.scss';


//-----------------------------------------------
// Colores
//-----------------------------------------------

$colorPrimary: #4697B4;
$colorPrimaryLight: #A4DDDB;
$colorPrimaryDark: #124A8B;
$colorPrimaryDarkest: #023268;
$colorSecondary: #FDD451;
$colorSecondaryDark: #e4b92e;
$colorSecondaryDarkest: $colorSecondaryDark;

$colorCyanLight: #DEF7FF;

$colorRed: #BF3061;
$colorRedLight: #EB8184;

$colorGreen: #277579;

$colorBrown: #CD9423;