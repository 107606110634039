/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700|Roboto:300,400,700");
// @import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700');

@import "constants.scss";
@import "project-constants.scss";
@import "general.scss";
@import "project-general.scss";

html,
body {
  background: #fff;
  margin: 0px;
  width: 100%;
  height: 100%;
  font-family: $fontDefault;
  font-weight: 400;
  font-size: 15px;
  color: #2b2b2b;
}

@import "project-styles.scss";