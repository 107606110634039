$fontDefault: Roboto, Helvetica, sans-serif;
$fontSecondary: "Roboto Condensed", Helvetica, sans-serif;

//-----------------------------------------------
// Colores
//-----------------------------------------------

$colorPrimary: #5677c3;
$colorPrimaryLight: ligthen($colorPrimary, 5%);
$colorPrimaryDark: darken($colorPrimary, 5%);
$colorPrimaryDarkest: darken($colorPrimary, 10%);
$colorSecondary: #f0b528;
$colorThird: #2b2b2b;

$colorWhite: #fff;
$colorGrayLightest: #efefef;
$colorGrayLight: #ddd;
$colorGray: #999;
$colorGrayDark: #666;
$colorGrayDarkest: #333;

$colorIconOK: #8bc34a;
$colorIconNOK: #ef5350;

$colorBackgroundError: #ffdee0;
$colorErrorMessage: #ec535e;
$colorSuccessMessage: #669933;

$colorBackgroundLight: #fafafa;
$colorIcon: #727272;

$colorElementHover: #fcf8e3;

//-----------------------------
// Colores Menu / submenu
//-----------------------------

$colorMenu: #2b2b2b;
$colorMenuBackground: $colorSecondary;
$colorMenuBorder: #fff;
$colorMenuBackgroundHover: lighten($colorSecondary, 10%);
$colorMenuHover: #333;
$colorSubMenuBorder: #f7f7f7;
$colorSubMenuBackgroundEven: #fff;
// $colorSubMenuBackgroundEven: #f7f7f7;
$colorSubMenuBackgroundOdd: #fff;
$colorSubMenu: #333;
$colorSubMenuBackgroundHover: $colorSecondary;
$colorSubMenuHover: #2b2b2b;
// $colorMenuPrivateIcon: #ccc;
$colorMenuPrivateIcon: $colorSecondary;
$colorMenuArrow: #2b2b2b;
$colorMenuIcon: #2b2b2b;
$colorSeparatorBorder: #ddd;

// Calendar

$calendarBgColor: #fafafa;
$calendarHeaderBgColor: $colorPrimary;
$calendarHeaderFontColor: #fafafa;
$calendarHeaderArrowColor: #fff;
$calendarHeaderDaysBgColor: #fafafa;
$calendarHeaderDaysFontColor: #363636;

//-----------------------------------------------
// Mixins
//-----------------------------------------------

@mixin z-depth($depth, $opacity: 0.2) {
  box-shadow: 0 #{$depth}px #{2 * $depth}px rgba(0, 0, 0, $opacity) !important;
  transition: box-shadow 0.3s;
}

@mixin material-shadow() {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.1),
    0 8px 10px 1px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05);
}

@mixin img-circle($diameter: 100) {
  object-fit: cover;
  width: #{$diameter}px;
  height: #{$diameter}px;
  border-radius: #{$diameter}px;
}

@mixin img-square($diameter: 100) {
  object-fit: cover;
  width: #{$diameter}px;
  height: #{$diameter}px;
}

@mixin img-resize($width: 320, $height: 180) {
  object-fit: cover;
  width: #{$width}px;
  height: #{$height}px;
}

@mixin img-resize-free($width: "100%", $height: "180px") {
  object-fit: cover;
  width: #{$width};
  height: #{$height};
}

@mixin img-resize-contain($width: 320, $height: 180) {
  object-fit: contain;
  width: #{$width}px;
  height: #{$height}px;
}

@mixin img-resize-contain-free($width: "100%", $height: "180px") {
  object-fit: contain;
  width: #{$width};
  height: #{$height};
}

@mixin img-circle($diameter: 100) {
  object-fit: cover;
  width: #{$diameter}px;
  height: #{$diameter}px;
  border-radius: #{$diameter}px;
}

@mixin img-rounded($width: 40, $height: 40, $radius: 5) {
  object-fit: cover;
  width: #{$width}px;
  height: #{$height}px;
  border-radius: #{$radius}px;
}