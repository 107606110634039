//-------------------------------------------
// Clases y estilos generales
//-------------------------------------------

.width-100 {
  width: 100%;
}

.width-66 {
  width: 66%;
}

.width-50 {
  width: 50%;
}

.width-33 {
  width: 33%;
}

.height-100 {
  height: 100%;
}

.height-66 {
  height: 66%;
}

.height-50 {
  height: 50%;
}

.height-33 {
  height: 33%;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clear {
  clear: both;
}

.material-shadow {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.1),
    0 8px 10px 1px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05);
}

.nowrap {
  word-break: normal !important;
}

.img-responsive {
  width: 100%;
}

.center-block {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.img-rounded {
  border-radius: 50% 50%;
}

.todo {
  background: red;
}

a {
  color: #218cdb;
  outline: none;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.truncate {
  width: 100%;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


//-------------------------------------------
// Negrita. En Firefox no se apreciaba la negrita se sube el weight a 700
//-------------------------------------------
strong {
  font-weight: 700;
}

button:disabled {
  cursor: not-allowed !important;
  pointer-events: none;
}

button.disabled {
  cursor: not-allowed !important;
  opacity: 0.38;
  pointer-events: none;
}

.mat-input-suffix {
  color: $colorGray !important;
}

//-------------------------------------------
// Dialogs
//-------------------------------------------

.dialog {
  .header {
    margin-left: -24px;
    margin-right: -24px;
    padding: 0 24px 24px 24px;
    border-bottom: solid 1px #ddd;

    .subsection {
      color: $colorSecondary !important;
    }

    [mat-dialog-title] {
      margin: 0;
      font-weight: 700;
      font-size: 24px;
      line-height: 1.4em;

      @media (max-width: 600px) {
        font-size: 18px;
      }

      .subsection {
        color: $colorPrimary;
        font-size: 18px;

        @media (max-width: 600px) {
          font-size: 15px;
        }

        &:before {
          content: "|";
          margin: 0 10px 0 5px;
          color: $colorGray;
          font-size: 18px;

          @media (max-width: 600px) {
            font-size: 15px;
          }
        }
      }

      .mat-icon {
        color: $colorGray;
      }
    }

    h3 {
      margin: 5px 0 0 0;
      font-weight: 400;
      font-size: 15px;
      color: $colorGrayDark;

      @media (max-width: 600px) {
        font-size: 14px;
        margin-top: 2px;
      }
    }

    .help {
      margin-top: -24px;
      margin-bottom: -24px;

      button.help-button mat-icon {
        color: $colorIcon;
      }
    }
  }

  mat-dialog-content {
    margin-left: -24px;
    margin-right: -24px;
    padding: 24px;
    background: $colorBackgroundLight;
    font-size: 14px;
    line-height: 1.4em;

    h2 {
      margin-top: 0;
      font-size: 20px;

      @media (max-width: 600px) {
        font-size: 18px;
      }
    }

    @media (max-width: 600px) {
      padding: 15px;
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  mat-dialog-actions {
    margin-left: -24px;
    margin-right: -24px;
    padding: 15px;
    border-top: solid 1px #ddd;
  }
}

//-------------------------------------------
// Popup Dialogs (Emergentes)
//-------------------------------------------

.popup-dialog {
  max-width: 900px !important;

  .header {
    @media (max-width: 600px) {
      padding: 12px 24px 0px 24px;
    }

    h2,
    h3 {
      font-family: $fontDefault;
    }

    h2 {
      margin: 0;
      font-weight: 700;
      font-size: 22px;

      @media (max-width: 600px) {
        font-size: 17px;
      }
    }

    h3 {
      margin-top: 5px;
      font-weight: 400;
      font-size: 17px;

      @media (max-width: 600px) {
        font-size: 14px;
      }
    }
  }

  .mat-dialog-content {
    padding: 0;

    @media (max-width: 600px) {
      font-size: 12px;
    }
  }

  .mat-dialog-actions {
    margin-left: -24px;
    margin-right: -24px;
    padding: 12px;
    background: white;
    border-top: solid 1px #ddd;
  }
}

//-------------------------------------------
// Chips
//-------------------------------------------

.mat-chip {
  padding: 3px 10px !important;
  font-size: 12px !important;
}

//-------------------------------------------
// Forms
//-------------------------------------------

// Campos de formulario disabled
mat-form-field.disabled .mat-form-field-underline {
  background-color: transparent !important;
  border-bottom: dashed 1px rgba(0, 0, 0, 0.25);
}

// Campos de formulario read-only
input:read-only {
  color: $colorGray;
  font-weight: 700;
}

.mat-form-field {
  font-size: 15px;
}

.file-input {
  .label {
    margin-bottom: 2px;
    font-size: 13px;
    font-weight: 700;
  }

  .file-input-container {
    padding: 4px;
    background: white;
    border: solid 1px #ccc;

    .file-name {
      width: 100%;
      text-align: left;
      font-size: 13px;

      .size {
        font-size: 12px;
        color: #999;
      }
    }

    .mat-icon.delete {
      color: $colorIconNOK;
    }
  }

  .mat-hint {
    display: block;
    margin-top: 4px;
    font-size: 12px;
  }
}

//-------------------------------------------
// Table container
//-------------------------------------------

.table-container {
  width: 100%;
  overflow-x: auto;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.1),
    0 8px 10px 1px rgba(0, 0, 0, 0.05), 0 3px 14px 2px rgba(0, 0, 0, 0.05);
}

.table-container.no-shadow {
  box-shadow: none;
  border: solid 1px #ddd;
}

.table-container.no-shadow.no-border {
  box-shadow: none;
  border: none;
}

//-------------------------------------------
// Table
//-------------------------------------------

table.table {
  width: 100%;
  min-width: 700px;
  border-collapse: collapse;
  background: #fff;

  thead {
    th {
      text-align: left;
      padding: 15px;
      color: $colorGrayDarkest;
      font-weight: 700;
      font-size: 13px;
      border-bottom: solid 1px #ddd;
    }

    th.filter {
      padding: 0;
      background: #fafafa;

      mat-form-field {
        min-width: 250px;
        margin: 0 24px;
        font-size: 14px;
      }

      mat-select {
        min-width: 150px;
        font-size: 14px;
      }
    }
  }

  tbody {
    mat-icon {
      color: #ccc;
    }

    td {
      padding: 5px 15px;

      button mat-icon {
        color: $colorIcon;
      }
    }

    tr:not(:last-child)>td {
      border-bottom: solid 1px #ddd;
    }
  }

  td.center,
  th.center {
    text-align: center !important;
  }

  td.right,
  th.right {
    text-align: right !important;
  }
}

table.table-striped tbody tr:nth-child(odd)>td {
  background: #fafafa;
}

//-------------------------------------------
// Tabs
//-------------------------------------------

::ng-deep .mat-tab-label {
  min-width: 100px !important;
  background: #fff;
}

::ng-deep .mat-tab-group.mat-primary .mat-tab-label:focus,
.mat-tab-group.mat-primary .mat-tab-link:focus,
.mat-tab-nav-bar.mat-primary .mat-tab-label:focus,
.mat-tab-nav-bar.mat-primary .mat-tab-link:focus {
  background-color: rgba($colorPrimary, 0.06);
}

//-------------------------------------------
// Mensajes generalistas
//-------------------------------------------

.info-message {
  padding: 24px;
  border-radius: 3px;
  background: $colorElementHover;
  color: darken($colorSecondary, 15);

  @media (max-width: 600px) {
    padding: 15px;
    font-size: 12px;
  }
}

.message-ok {
  padding: 24px;
  color: rgb(26, 70, 9);
  background: #dff0d8;
  font-size: 14px;
  font-weight: 400;
  border: solid 1px darken(#dff0d8, 5%);

  @media (max-width: 600px) {
    font-size: 12px;
  }
}

.message-error {
  padding: 24px;
  color: rgb(90, 21, 21);
  background: #f2dede;
  font-size: 14px;
  font-weight: 400;
  border: solid 1px darken(#f2dede, 5%);

  @media (max-width: 600px) {
    font-size: 12px;
  }
}

//-------------------------------------------
// Errores
//-------------------------------------------

.error-message {
  color: $colorErrorMessage;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}

//-------------------------------------------
// Checkbox
//-------------------------------------------

.mat-checkbox {
  line-height: 24px;
}

.mat-checkbox-label {
  white-space: initial !important;
  line-height: 18px !important;
}

.mat-checkbox-inner-container {
  margin-top: 0 !important;
}

.mat-checkbox.ng-invalid.ng-touched {
  color: #f44336;

  .mat-checkbox-frame {
    border: 2px solid #f44336;
  }
}

.conditions-check {
  display: block;
}

//-------------------------------------------
// checkboxes - Corregir error de renderizado en multi-columnas
//-------------------------------------------

.mat-checkbox-layout {
  position: relative;
}

//-------------------------------------------
// Button Toggle Group
//-------------------------------------------

mat-button-toggle-group {
  background: #fff;
  font-weight: 400;

  .mat-button-toggle-label-content {
    padding: 4px 16px !important;
    color: $colorGray;
    font-size: 14px;
  }

  .mat-button-toggle-checked {
    background-color: $colorSecondary !important;

    .mat-button-toggle-label-content {
      color: #fff !important;
    }
  }
}

//-------------------------------------------
// Paginacion
//-------------------------------------------

::ng-deep .web-pagination .ngx-pagination {
  .current {
    padding: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 30px;
    background: $colorSecondary !important;
    font-size: 13px;
    text-align: center;
  }

  a {
    padding: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 30px;
    outline: none;
    font-size: 13px;
    text-align: center;
  }

  .pagination-next a,
  .pagination-previous a,
  .pagination-next span,
  .pagination-previous span {
    width: auto;
    height: auto;
    line-height: auto;
    border-radius: 20px;
    padding: 2px 15px;
    outline: none;
    font-size: 13px;
    text-align: center;
  }
}

//-------------------------------------------
// Textos
//-------------------------------------------

.text-content {
  font-size: 14px;
  color: #000;
  line-height: 1.4em;
  font-weight: 300;

  @media (max-width: 600px) {
    font-size: 12px;
  }

  ul,
  ol {
    padding-left: 32px;

    @media (max-width: 600px) {
      padding-left: 16px;
    }

    li {
      margin: 8px 0;
    }
  }

  a {
    word-break: break-word;
  }
}

// MANTENER ESTO COMENTADO !!!
// Edu - 30/03/22 - Hay que anular estos estilos desde aqui, no se pueden hacer superponiendo otra regla en personalizacion
// ::ng-deep .tinymce-content {
//     img {
//         width:100% !important;
//         height:auto !important;
//     }
// }

//-------------------------------------------
// Listas
//-------------------------------------------

ul,
ol {
  @media (max-width: 600px) {
    padding-left: 12px;
  }
}

//-------------------------------------------
// Snackbars
//-------------------------------------------

.snackbar {
  background: $colorPrimary !important;
  white-space: pre-wrap;

  &.snackbar-error {
    background: $colorErrorMessage !important;
  }

  &.snackbar-ok {
    background: $colorSuccessMessage !important;
  }
}

//-------------------------------------------
// Captcha
//-------------------------------------------

re-captcha {
  display: block;
  margin-top: 16px;
  transform: scale(0.84);
  transform-origin: 0 0;

  &.ng-invalid.ng-touched iframe {
    border: 2px solid #f44336;
  }
}

.captcha {
  margin-top: 15px;
  margin-bottom: 10px;

  .mat-error {
    font-size: 11px;
  }
}

//-------------------------------------------
// Paginacion
//-------------------------------------------

.web-pagination {
  text-align: center;

  a {
    text-decoration: none;
    outline: none;
  }
}

//-------------------------------------------
// Buttons
//-------------------------------------------

button.cancel-button {
  background: #ccc;
  color: #333;
}

//-------------------------------------------
// Scrollbar
// Solo para navegadores basados en Webkit
//-------------------------------------------

.thin-scroll {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  // &::-webkit-scrollbar-corner { background-color: rgba(0,0,0,.3); }
}