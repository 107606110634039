.structure-container {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 24px;

  @media(max-width: 960px) {
    padding: 0 12px;
  }
}

.structure-container-full-width {
  margin-left: calc((1140px - 100vw - 32px) / 2);
  width: calc(100vw - 16px);

  @media (max-width: 1140px) {
    margin-left: -24px;
  }

  @media (max-width: 960px) {
    margin-left: -12px;
  }

  @media (max-width: 600px) {
    width: 100vw;
  }
}

a {
  color: $colorPrimary;
}

.material-shadow {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.05),
    0 8px 10px 1px rgba(0, 0, 0, 0.025), 0 3px 14px 2px rgba(0, 0, 0, 0.025);
}

//-------------------------------------------
// Textos
//-------------------------------------------

.text-content {
  font-size: 15px;
  color: #000;
  line-height: 1.5em;
  font-weight: 400;
  text-wrap: pretty;

  @media (max-width: 600px) {
    font-size: 12px;
  }

  ul,
  ol {
    padding-left: 32px;

    @media (max-width: 600px) {
      padding-left: 16px;
    }

    li {
      margin: 8px 0;
    }
  }
}

//----------------------------------------------
// Botones
//----------------------------------------------

::ng-deep .mat-flat-button.mat-accent,
::ng-deep .mat-raised-button.mat-accent,
::ng-deep .mat-fab.mat-accent,
::ng-deep .mat-mini-fab.mat-accent {
  color: white !important;
}

//-------------------------------------------
// Mensajes generalistas
//-------------------------------------------

.info-message {
  padding: 24px;
  background: $colorElementHover;
  color: darken($colorSecondaryDarkest, 15%);
  border: solid 1px rgba($colorSecondary, 0.5);

  @media (max-width: 600px) {
    padding: 15px;
    font-size: 12px;
  }
}

.message-ok,
.message-error,
.info-message {
  border-radius: 10px;
}

//-------------------------------------------
// Fechas (clase .date)
//-------------------------------------------

.date {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 13px;
  color: $colorPrimaryDarkest;
  font-weight: 600;

  mat-icon {
    font-size: 16px;
    width: 16px;
    height: 16px;
    color: $colorPrimaryLight;
  }
}

//-------------------------------------------
// Clases para usar en los contenidos estáticos del panel
//-------------------------------------------

::ng-deep .tinymce-content {
  img.imagen-cineforum {
    width: auto !important;

    @media (max-width: 960px) {
      width: 100% !important;
    }
  }

  img {
    height: auto;
    object-fit: contain;
    border-radius: 10px;

    @media(max-width: 600px) {
      width: 100%;
      height: auto;
    }
  }

  // Botones / Links usados en un tinymce
  .mat-flat-button {
    border: none;
    border-radius: 5px;
    padding: 4px 20px;
    background: white;
    color: #2b2b2b;
    text-decoration: none;
    outline: none;
    cursor: pointer;

    &:hover {
      background: #f0f0f0;
    }

    &.color-primary {
      background: $colorPrimary;
      color: white;

      &:hover {
        background: $colorPrimaryDark;
      }
    }

    &.color-secondary {
      background: $colorSecondary;
      color: black;

      &:hover {
        background: $colorSecondaryDark;
      }
    }
  }

  // Video responsive
  .video-responsive {
    position: relative;
    padding-bottom: 56.25%;
    /* 16/9 ratio */
    padding-top: 30px;
    /* IE6 workaround*/
    height: 0;
    overflow: hidden;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  // Estilos copiados del skin del editor (node_modules\tinymce\skins\content\default\content.css)
  code {
    background-color: #e8e8e8;
    border-radius: 3px;
    padding: 0.1rem 0.2rem;
  }

  blockquote {
    border-left: 2px solid #ccc;
    margin-left: 1.5rem;
    padding-left: 1rem;
  }

  table {
    border-collapse: collapse;
  }

  table th,
  table td {
    border: 1px solid #ccc;
    padding: 0.4rem;
  }
}

//----------------------------------------------
// Dialogs
//----------------------------------------------

mat-dialog-container {
  border-radius: 10px !important;
}

//-------------------------------------------
// Contenidos deshabilitados por cookies
//-------------------------------------------

.content-disabled-by-cookies {
  margin: 15px auto;
  max-width: 600px;
  background: #f7f7f7;
  padding: 8px 15px;
  font-size: 12px;
  text-align: center;
  line-height: 1.4em;
  border: dashed 1px #ccc;
}


//-------------------------------------------
// Animaciones personalizadas para usar con la librería AOS
//-------------------------------------------

[data-aos="fade-in"] {
  opacity: 0;
  transition-property: transform, opacity;

  &.aos-animate {
    opacity: 1;
  }
}